import React from "react";
import ENV from "../../utils/env";
import PropTypes from "prop-types";
import get from "lodash/get";
const Viewer = (props) => {
    return (
      <div className={["viewer",
      !get(props, "noAmenities", true) && "no_amenities"
      ].join(" ")}>
          <iframe
            title={"WebVrWrapper"}
            allowvr="yes"
            key={props.token}
            src={`${ENV.app_url}/viewer?token=${props.token}&is_amenity=${props.isAmenity}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
      </div>
    );
}

Viewer.propTypes = {
  token: PropTypes.string.isRequired,
  isAmenity: PropTypes.bool,
  noAmenities: PropTypes.bool
};

export default Viewer;

/* eslint-disable no-undef */

import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { API } from "@piiqtechnologies/components-library";
import { Baths, Beds, Star } from "@piiqtechnologies/piiq-icon-library";
import "./listing_title.scss";
import ENV from "../../../utils/env";
// const comingsoon =  require("./comingsoon.png");
import comingsoon from "./comingsoon.png";
window.console.log(comingsoon);

const ListingTile = (props) => {
  const [showAmenties, setShowAmenities] = useState(false);
  const absolute_rgb = getComputedStyle(document.documentElement).getPropertyValue("--blue");
  const rgbaColor = absolute_rgb.toString().replace("#", "").trim().convertToRGB();
  rgbaColor.push(0.5);
  const getStatus = () => {
    const { is_archived, is_sales_listing, is_coming_soon } = props.data;
    if (is_archived || is_coming_soon) {
      if (is_archived && is_sales_listing) {
        return "Sold";
      }
      if (is_archived) {
        return "Rented";
      }
    }
    if (is_coming_soon) {
      return "Coming soon";
    }
    return "";
  }
  const showRentFlag = () => {
    if (get(props, "data.is_sales_listing", false)) {
      return get(props, "data.price", 0) !== 0;
    }
    return get(props, "data.rent", 0) !== 0;
  };
  const thumbnailUrl = encodeURI(ENV.image_base_path + props.data.thumbnails[0]);  
  const address = get(props, "data.building.address", "");
  
  const alternateName = get(props.data, "building.alternate_name", "");
  const unit = get(props.data, "unit", "");

  const formattedAlternateName = (alternateName && address) ? `${alternateName} (${address})` : alternateName;
  const formattedUnit = formattedAlternateName ? `, ${unit}` : unit;

  return <div className={[
    "listing__info__tile",
    get(props, "selectedListingId", "") === get(props.data, "_id", "") && "is_active"
    ].join(" ")} onClick={() => props.onClick && props.onClick()} title={`${get(props.data, "building.alternate_name", "")} ${get(props.data, "building.alternate_name", "") && "(" || ""} ${address} ${get(props.data, "building.alternate_name", "") && ")" || ""} ${get(props.data, "unit", "")}`}>
    <div className="thumbnail__container">  
      <div className="thumbnail__wrapper">
        <img src={`${thumbnailUrl}`} onError={(e) => {
          e.target.src = comingsoon;
        }}  alt="image__path" />
        {showRentFlag() && <span className="price">
          {!get(props, "data.is_sales_listing", false) && `${API.convertIntoCurrency(get(props, "data.rent", 0))}/mo` || API.convertIntoCurrency(get(props, "data.price", 0))}
        </span> || null}
        {props.showStatus && getStatus() && <span className="tag" style={{backgroundColor: `rgba(${rgbaColor.join()})`}}>
          {getStatus()}
        </span> || null}
      </div>
    </div>
    <div className="listing_information_wrapper">
      <div className="address_wrapper">
          {`${formattedAlternateName}${formattedUnit}`}
      </div>
      <div className="addon__information">
        <div className="bath_bed_info">
          <div className="bedroom_unit_wrapper">
            <span>
              <Beds />
            </span>
            <span className="value">
              {get(props, "data.bedroom_unit", 0)}
            </span>
          </div>
          <div className="bedroom_unit_wrapper">
            <span>
              <Baths />
            </span>
            <span className="value">
              {get(props, "data.bathroom_unit", 0)}
            </span>
          </div>
        </div>
        <div>
          { get(props, "data.amenities", []).length + get(props, "data.building_amenities", []).length && <button onClick={(e) => {
            e.stopPropagation();
            setShowAmenities(showAmenties => !showAmenties);
          }}>
            <Star />
            {(get(props, "data.amenities", []).length + get(props, "data.building_amenities", []).length) && get(props, "data.amenities", []).length + get(props, "data.building_amenities", []).length || null} Amenities
          </button> || null}
        </div>
      </div>
    </div>
    {showAmenties &&  <div className="listing__amenities__wrapper">
      {get(props, "data.amenities", []).length && <div className="listing__amenity_list">
        <span>In-unit amenities: </span>
        <ul>
          {get(props, "data.amenities", []).map((amenity, key) => {
            return <li className="amenities_list" key={key}>
              <span>
                &bull; 
              </span>
              <span>
                {amenity}
              </span>
            </li>;
          })}
        </ul>
      </div> || null}
      {get(props, "data.building_amenities", []).length && <div className="listing__amenity_list">
        <span>Property amenities: </span>
        <ul>{get(props, "data.building_amenities", []).map((amenity, index) => {
          return <li className="amenities_list" key={index}>
            <span>
              &bull; 
            </span>
            <span>
              {amenity}
            </span>
          </li>;
        })}
        </ul>
      </div> || null}
    </div> || null}
  </div>;
};

ListingTile.defaultProps = {
  showStatus: true
};

ListingTile.propTypes = {
  data: PropTypes.object.isRequired,
  showStatus: PropTypes.bool,
  selectedListingId: PropTypes.string,
  onClick: PropTypes.func
};

export default React.memo(ListingTile);

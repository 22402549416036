import React, { useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import "./amenityStyle.scss";

const AmenitySlider = (props) => {
    useEffect(() => {
    }, []);
    
    const convertToLowerCase = (string = "") => {
        string = (string && string.toLowerCase()) || "";
        string = string.charAt(0).toUpperCase() + string.slice(1);
        return string; 
    }
    return (<div className="amenity__slider_wrapper">
        <div className="slider__container">
            {get(props, "data", []).map((amenity, index) => {
                return ((get(props, "seeMoreAmenities", false) || (!get(props, "seeMoreAmenities", false) && index < 3))  && <div className="slider__amenity_labels" onClick={() => {props.changeAmenity(index)}} key={index}>{convertToLowerCase(get(amenity, 'label', ""))}</div>) || null
            })}
        </div>
    </div>)
}

AmenitySlider.propTypes = {
    data: PropTypes.array.isRequired,
    changeAmenity: PropTypes.func.isRequired,
    seeMoreAmenities: PropTypes.bool
  };

export default AmenitySlider;
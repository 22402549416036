import React from "react";
import ENV from "../utils/env";
export default class imageLoader extends React.Component {
  imageLoaded = false;
  isError = false;
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded: false,
      isError: false,
    };
    this.loaded = this.loaded.bind(this);
    this.errorLoadingImage = this.errorLoadingImage.bind(this);
  }
  loaded() {
    this.setState(() => {
      return {
        imageLoaded: true,
      };
    });
  }
  errorLoadingImage() {
    this.setState(() => {
      return {
        isError: true,
      };
    });
  }
  render() {
    // {!this.imageLoaded && !this.isError && <Loader />}
    return (
      <div className="image-wrapper">
       {(this.props.status.is_archived || this.props.status.is_coming_soon) && <div className="listing-status">{this.props.status.is_sales_listing && this.props.status.is_archived ? 'SOLD' :  this.props.status.is_archived ? 'RENTED': 'COMING SOON'}</div>}
        {
          <img
            onLoad={this.loaded}
            onError={this.errorLoadingImage}
            src={ENV.image_base_path + this.props.image}
            hidden={!this.state.imageLoaded}
            alt={this.props.image}
          />
        }
        {(!this.state.imageLoaded || this.state.isError) && (
          <div className="icon">
            <i className="fa fa-home" aria-hidden="true"></i>
          </div>
        )}
      </div>
    );
  }
}

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app";
// import Grid from "./components/grid/Grid";
// import PropertyGrid from "./components/propertyGrid/PropertyGrid";
// import Amenities from "./components/propertyGrid/Amenities";
import NewAmenities from "./components/propertyGrid/NewAmenities";
import PropertyWidget from "./components/propertyWidget/property";
import ListingWidget from "./components/listingWidget/listingWidget";
import NewGridWidget from "./components/newGrid/newGrid";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

const routes = (
  <Router>
    <div>
    <Switch>
      <Route exact path="/">
        <Redirect to="/grid" />
      </Route>
      <Route
        path="/grid"
        component={NewGridWidget}
        />
        <Route
        path="/oldGrid"
        component={App}
        />
        <Route
          exact path="/propertyGrid"
          component={PropertyWidget}
          // component={PropertyGrid}
        />
        <Route
          path="/propertyGrid/amenities"
          component={NewAmenities}
          exact
        >
        </Route>
        <Route
          path="/property"
          component={PropertyWidget}
          exact
        >
        </Route>
        <Route
          path="/listing"
          component={ListingWidget}
          exact
        >
        </Route>
        <Route
        path="/amenities"
        component={NewAmenities}
        exact
      >
      </Route>
        </Switch>
        
    </div>
  </Router>
);
ReactDOM.render(routes, document.getElementById("root"));

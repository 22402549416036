import React from "react";
import "./App.scss";
import ListingService from "./services/listing.service";
// import ENV from "./utils/env";
import qs from "qs";
import Listings from "./components/listings";
import Loader from "./components/loader";
import MetaTags from "react-meta-tags";
import Footer from "./components/footer";


export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.queryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    console.log(this.queryParams);
    this.state = {
      building_data: {},
      is_loading: true,
      no_result: false,
      queryParams: this.queryParams,
      br_filters: [],
      branding: {},
      listings: [],
      total_listing_count: 0,
    };
    this.scrollTop = 0;
    this.location = window.location.origin || "";
    this.query = "";
    this.start_index = 0;
    this.limit = 12;
    this.getAllListings = this.getAllListings.bind(this);
    this.listingService = new ListingService();
  }
  componentDidMount() {
    if (this.queryParams.agent_id || this.queryParams.building) {
      this.getAllListings();
    } else {
      this.setState(() => {
        return {
          no_result: true,
        };
      });
    }
    if (this.state.queryParams && !this.state.queryParams.building) {
      window.onscroll = () => {
        const {
          scrollTop,
          scrollHeight,
          clientHeight,
        } = document.documentElement;
        setTimeout(() => {
          if (
            this.state.queryParams &&
            this.state.queryParams.is_messageParam_required
          ) {
            window.parent.postMessage(
              { listings: this.state.listings, scrollTop: scrollTop },
              "*"
            );
          }
          if (
            scrollTop + clientHeight >= scrollHeight - 1 &&
            scrollTop > this.scrollTop
          ) {
            if (this.state.listings.length < this.state.total_listing_count) {
              if (this.start_index === 0) {
                this.start_index = this.limit + 1;
                // console.log("first_call");
              } else {
                this.start_index = this.start_index + this.limit;
                // console.log("Second Call");
              }
              this.scrollTop = scrollTop;
              this.getAllListings();
              console.log(this.start_index);
            }
          }
        }, 500);
        // setTimeout(() => {
        //    // if (this.deviceDetectorService.isDesktop() && this.nearByListings.length > 3) {
        //   //   this.stickToTop();
        //   // } else if (this.deviceDetectorService.isMobile() && this.nearByListings.length > 1) {
        //   //   this.stickToTop();
        //   // }
        //   if ((window.innerHeight + window.scrollY + 10) >= (document.body.scrollHeight)) {
        //     console.log('Demo');
        //       // // you're at the bottom of the page
        //       // _that.isScrolling = false;
        //       // if (_that.nearByListings.length < _that.totalNearByListingsCount) {
        //       //   setTimeout(() => {
        //       //     _that.getNearByListings();
        //       //   }, 500);
        //       // } else {
        //       //   return;
        //       // }
        //   }
        // });
      };

      // window.onresize = () => {
      //   if(this.state.queryParams && this.state.queryParams.is_messageParam_required){
      //     const {offsetHeight} = document.documentElement;
      //     window.parent.postMessage(
      //       {
      //         listings: this.state.listings,
      //         scrollTop: this.scrollTop,
      //         offsetHeight: offsetHeight,
      //       },
      //       "*"
      //     );

      //   }
      // }
    }
  }

  getAllListings() {
    const apiKey =
      this.queryParams && this.queryParams.building
        ? "getListingAddress"
        : "getAllListings";
    if (this.queryParams.building) {
      this.query = {
        address: this.queryParams.building,
        bedroom_unit: {
          // min: (this.queryParams.unit || this.queryParams.unit === '0' ? parseFloat(this.queryParams.unit) : 0),
          // max: (this.queryParams.unit || this.queryParams.unit === '0' ? parseFloat(this.queryParams.unit) : 5),
          min: this.queryParams.unit === undefined ? 0 : parseFloat(this.queryParams.unit),
          max: this.queryParams.unit === undefined ? 5 : parseFloat(this.queryParams.unit)
        },
      };
    } else {
      this.query = "";
      let params = {
        agent_id: this.queryParams.agent_id || this.queryParams.agent,
        start_index: this.start_index,
        limit: this.limit,
        bedrooms: this.queryParams.bedrooms,
        bathrooms: this.queryParams.bathrooms,
        address: this.queryParams.building || this.queryParams.address || "",
        exclude_listings: this.queryParams.exclude_listings,
        is_grid: this.queryParams.is_grid,
        is_coming_soon: this.queryParams.show_csoon,
        is_archived: this.queryParams.display_archived,
        is_public: this.queryParams.show_hidden,
        latitude: this.queryParams.latitude,
        longitude: this.queryParams.longitude,
        bedrooms_str: this.queryParams.bedrooms_str,
      };
      for (const key in params) {
        if (
          params.hasOwnProperty(key) &&
          (params[key] || params[key] === false)
        ) {
          this.query += `${key}=${params[key]}&`;
        }
      }
      this.query = this.query.substr(0, this.query.length - 1);
    }
    this.listingService[apiKey](this.query)
      .then((response) => {
        this.setState(() => {
          return {
            is_loading: false,
          };
        });
        const { data } = response.data;
        if (!response.data.success) {
          console.log(response.data.message);
          this.setState(() => {
            return {
              no_result: true,
            };
          });
          return;
        }
        const all_listing =
          [...this.state.listings, ...data.listings] || data.listings;
        this.setState((prevState) => {
          console.log(prevState);
          return {
            branding: data && data.listings[0] && data.listings[0].building,
            is_loading: false,
            building_data: data,
            listings: all_listing,
            total_listing_count: data.total_count,
            no_result:
              data && data.listings && data.listings.length > 0 ? false : true,
          };
        });
        const { offsetHeight } = document.documentElement;
        if (
          this.state.queryParams &&
          this.state.queryParams.is_messageParam_required
        ) {
          window.parent.postMessage(
            {
              listings: this.state.listings,
              scrollTop: this.scrollTop,
              offsetHeight: offsetHeight,
              isApt: false
            },
            "*"
          );
        }
      })
      .catch((err) => {
        this.setState(() => {
          return {
            is_loading: false,
            no_result: true,
          };
        });
        console.log(err);
      });
  }

  applyBedroomFilters(filter) {
    let allFilters = [...this.state.br_filters];
    const index = allFilters.indexOf(filter);
    if (index > -1) {
      allFilters.splice(index, 1);
    } else {
      allFilters.push(filter);
    }
    this.setState(() => {
      return {
        br_filters: allFilters,
      };
    });
    this.start_index = 0;
    this.queryParams["bedrooms_str"] =
      allFilters && !allFilters.length ? undefined : allFilters.toString();
    this.setState(() => {
      return {
        queryParams: this.queryParams,
        listings: [],
      };
    });
    console.log(this.state);
    this.getAllListings();
  }

  render() {
    return (
      <>
      <div
        className="main-wrapper"
        style={{
          backgroundColor: `#${
            this.state.queryParams &&
            this.state.queryParams.bg_color &&
            this.state &&
            !this.state.is_loading
              ? this.state.queryParams.bg_color
              : "ffffff"
          }`,
        }}
      >
        <MetaTags>
          <title>
            {`${
              (this.state &&
                this.state.branding &&
                this.state.branding.address) ||
              ""
            } Apartments for rent - Virtual Tours | ${this.location || ""}`}
          </title>
          <meta
            name="description"
            content={`${this.location || ""} - ${
              this.state.total_listing_count || ""
            } Virtual Tours of active listings in ${
              (this.state &&
                this.state.branding &&
                this.state.branding.address) ||
              ""
            }. Find out more about price, size, and amenities here.`}
          />
        </MetaTags>
        {this.state.no_result &&
          this.state.queryParams &&
          !this.state.queryParams.building && (
            <p className="no_result">No virtual tours found</p>
          )}
        {this.state && this.state.is_loading && !this.state.no_result && (
          <Loader />
        )}
        {this.state && !this.state.is_loading && !this.state.no_result && (
          <div className="filter-wrapper">
            {/* <div className="white-placeholder" style="background: rgb(255, 255, 255); width: 100%; height: 37px; position: fixed; z-index: 9999; opacity: 1;"></div> */}
            {this.state.queryParams &&
              this.state.queryParams.filter_bar === "true" && (
                <div
                  style={{
                    background: `#${this.state.queryParams.bg_color || "fff"}`,
                    width: "100%",
                    height: "37px",
                    position: "fixed",
                    zIndex: "9999",
                    opacity: this.state.queryParams.filter_bar ? 1 : 0,
                  }}
                  className="white-placeholder"
                ></div>
              )}
            {this.state.queryParams &&
              this.state.queryParams.filter_bar === "true" && (
                <div className="filters">
                  <div className="title">Bedrooms</div>
                  {this.state.building_data &&
                    this.state.building_data.bedrooms_count &&
                    this.state.building_data.bedrooms_count.length &&
                    this.state.building_data.bedrooms_count
                      .sort()
                      .map((value) => {
                        console.log("value", value)
                        return (
                          <span
                            key={value}
                            className={[
                              "options",
                              this.state.br_filters.includes(value)
                                ? "active"
                                : "",
                            ].join(" ")}
                            onClick={() => this.applyBedroomFilters(value)}
                            style={{
                              backgroundColor: this.state.br_filters.includes(
                                value
                              )
                                ? `#${this.state.queryParams.sec_color}`
                                : undefined,
                            }}
                          >
                            {value === 0 ? "Studio" : value}
                          </span>
                        );
                      })}
                </div>
              )}
            {
              <Listings
                branding={this.state.branding}
                listings={this.state.listings}
                queryParams={this.state.queryParams}
              />
            }
          </div>
        )}

      </div>
      {((this.queryParams.show_footer === 'true' && this.queryParams.is_grid !== 'true') || (this.queryParams.isAgentsLens === 'true')) && 
        <div className={
          // "footer_bar"+(this.queryParams.isAgentsLens ? "agents-footer-bar" : "")
          // [
            "footer_bar" + 
            this.state.listings && !this.state.listings.length
              ? " agents-footer-bar"
              : ""
  
          // ]
  
        }>
                <Footer/>
        </div>
        
      }
      
      
      </>
    );
  }
}


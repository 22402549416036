/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-no-target-blank */
import React, {Component} from 'react';
import "../../App.scss";
import ListingService from "../../services/listing.service";
import qs from "qs";
import ENV from "../../utils/env";
import MobileIframe from './MobileViewerRenderer'
import Slider from "../Slider";
import {  get } from "lodash";
import { ChevronDown, ChevronUp } from "@piiqtechnologies/piiq-icon-library";
import "./new_amenities.scss";

export default class NewAmenities extends Component{
    constructor(props) {
        super(props);
        this.queryParams = qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        });
        this.listingService = new ListingService();
        this.apiCallMadeFor = [];
        this.state = {
            is_loading: true,
            no_result: false,
            queryParams: this.queryParams,
            building: {},
            selectedAmenity: '',
            buildingAmenities: [],
            mobileBuildingAmenities: [],
            amenityToShow:{},
            amenitiesUrl: "",
            fullscreenMode: false,
            selectedIndex: 0,
            miniMode: true
        };
        this.location = window.location.origin || "";
        this.openAmenitiesInnewTab = this.openAmenitiesInnewTab.bind(this);
        this.getBrandingDetails = this.getBrandingDetails.bind(this);
    }

    escFunction(event){
        if(event.keyCode === 27) {
            this.setState({
                fullscreenMode : false
            });
        }
    }

    componentDidMount() {
        if(get(this.queryParams, "building_id", "")){
            this.getBrandingDetails("getBuildingDetails", "secondary_color", get(this.queryParams, "building_id", ""));
        } else if (get(this.queryParams, "agent_id", "")){
            this.getBrandingDetails("getAgentDetails", "accent_color", get(this.queryParams, "agent_id", ""));
        }
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);
        var self = this;
        function exitHandler() {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                self.setState({
                    fullscreenMode : false
                });
            }
        }

        this.getAllListings();
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
        eventer(messageEvent, (e) => {
            if(e.data === "fullscreen_button_clicked" || e.message === "fullscreen_button_clicked") {
                this.setState({
                    fullscreenMode : !this.state.fullscreenMode
                }, () => {
                    window.console.info("State Updated")
                });
                this.fullscreenToddle();
            }
        });
    }


    fullscreenToddle() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
         (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen();
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    getBrandingDetails = (func, key, value) => {
        this.listingService[func](value)
            .then((response) => {
            if (get(response, "data.status", "") !== "SUCCESS") {
                return;
            }
            const buildingInfo = get(response, "data.data.result", {});
            document.documentElement.style.setProperty("--blue", get(buildingInfo, key, "#04D2C6"));
            this.setState({
                building : buildingInfo,
                is_loading: false
            });
            })
            .catch((error) => {
            window.console.log(error);
            });
        }

    getAllListings() {
        let queryConfig = "";
        if(this.queryParams.building_id){
            queryConfig += `building_id=${this.queryParams.building_id}`;
        }
        if( this.queryParams.agent_id ) {
            queryConfig += `agent_id=${this.queryParams.agent_id}`;
        }
        if(!this.queryParams.building_id && !this.queryParams.agent_id){
            return;
        }
        this.listingService.getAllAmenities(queryConfig).then((response) => {
            if (get(response.data, "status", "ERROR") === "SUCCESS" && get(response.data, "data.result", []).length) {
                this.addIsAmenitiesInImages(get(response.data, "data.result", []), 0);
            }
        }).catch((err) => {
            this.setState(() => {
              return {
                is_loading: false,
                no_result: true,
              };
            });
            window.console.log(err);
        });
    }

    changeAminity(index) {
        if(this.state.selectedIndex !== index) {
            this.setState({
                amenityToShow: this.state.buildingAmenities[index],
                amenitiesUrl: "",
                selectedIndex: index
            });
            if(!this.state.amenityToShow.length) {
                this.addIsAmenitiesInImages(this.state.buildingAmenities, index);
            }
        }
    }

    addIsAmenitiesInImages(buildingAmenity, index) {
        if(!(Array.isArray(buildingAmenity) && buildingAmenity && buildingAmenity.length)) {
            return;
        }
        this.setState({
            buildingAmenities : buildingAmenity,
            amenityToShow: buildingAmenity[index]
        });
        this.createAmenitiesUrl(buildingAmenity[index]._id, 'desktop', index)
    }

    createAmenitiesUrl(id, callingFor, index) {
        this.listingService.getAmenityToken(id).then((response) => {
            const token = get(response.data, "data.result.token", "");
            if(token) {
                if(callingFor === 'desktop') {
                    this.setState({
                        amenitiesUrl: `${ENV.app_url}/viewer?token=${token}&is_amenity=true&isCarousel=true&isModal=true&inModalBackButtonOff=true`
                    })
                }
                else {
                    let items = [...this.state.buildingAmenities];
                    let item = {...items[index]};
                    item.token = token;
                    item.iframeSrc=  `${ENV.app_url}/viewer?token=${token}&is_amenity=true&isCarousel=true&isModal=true&inModalBackButtonOff=true`      // update the value of specific key
                    // items[index] = item;
                    // this.setState({buildingAmenities : items});
                    this.setState({
                        mobileBuildingAmenities: [...this.state.mobileBuildingAmenities, item]
                    });
                }

            }
        }).catch((err) => {
            console.log(err);
        });
    }


    renderMobileAmenities () {
        this.state.buildingAmenities.forEach((buildingAmenity,index) => {
            const ifExists = this.apiCallMadeFor.filter(_ba => _ba === buildingAmenity._id)
            if (ifExists.length === 0){
                this.createAmenitiesUrl(buildingAmenity._id, 'Mobile', index);
                this.apiCallMadeFor.push(buildingAmenity._id);
            }
        });

        return (
            this.state.mobileBuildingAmenities.map((buildingAmenity,index) =>
                <div key={index}>
                    <p className="amenityHeader">{buildingAmenity.label}</p>
                    <div>
                        {buildingAmenity.iframeSrc && <div  style={{ width:"100%", height:"272px", border: "none", position: "relative" }} className={this.state.showClassName}>
                           <MobileIframe src={buildingAmenity.iframeSrc}></MobileIframe>
                        </div>}
                    </div>
                </div>
            )
        )
    }

    openAmenitiesInnewTab (id) {
        this.listingService.getAmenityToken(id).then((response) => {
            if (response.data.data.result.token) {
                window.location.href = `${ENV.app_url}/viewer?token=${response.data.data.result.token}&is_amenity=true&fromShareable=true`;
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    render(){
        const selectorColor = {
            color: this.state.building.secondary_color ? this.state.building.secondary_color : "#1d1c38"
        };
        if (this.state.building.background_color)
           document.documentElement.style.setProperty("--amenitybackground", this.state.building.background_color);
        return  <>
            <div className="desktop___view">
                <div
                    className="main-wrapper-property-gird"
                    style={{
                        paddingBottom: "26px"
                    }}
                >
                    <>
                       { Object.entries(this.state.buildingAmenities).length && <>
                            <>
                                <h1 className="amenity_header">Amenities</h1>
                                <div style={{ marginTop: 21 }}>
                                    <Slider data={this.state.buildingAmenities} amenityKey={"label"} onChange={(index) => this.changeAminity(index)}
                                    selectorColor={selectorColor.color} firstAmenity={get(this.state, "buildingAmenities", false).length > 0 && this.state.buildingAmenities[0].label}/>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    {
                                        this.state.amenityToShow &&
                                            <iframe style={{borderRadius: "10px"}} title="amenity_title" allowvr="yes" src={this.state.amenitiesUrl} frameBorder='0'  className={this.state.showClassName +' '+ (this.state.fullscreenMode === true ? 'iframeContainer_fullscreen' : 'iframeContainer iframeContainerWidth')}></iframe>
                                    }
                                </div>
                            </>
                        </> || !this.state.is_loading && <p className="no_result">No virtual tours found</p>
                    }
                    </>
                </div>
            </div>
            <div className="mobile__view">
                <div className="header">
                    <div className="header__title">Community amenities</div>
                    {this.state.buildingAmenities.length > 3 && <div className="header__option" onClick={() => this.setState({ miniMode: !this.state.miniMode})}>
                       <span className="header__option__title" onClick={() => this.setState({ miniMode: !this.state.miniMode})}>See more</span>
                       <span>
                          {this.state.miniMode &&  <ChevronDown /> || <ChevronUp />}
                       </span>
                    </div> || null}
                </div>
                <div className="amenities__name__wrapper">
                    {this.state.miniMode && this.state.buildingAmenities.slice(0,3).map((amenities, key) => <button key={key} onClick={() => this.openAmenitiesInnewTab(amenities._id)}>
                        {amenities.label}
                    </button>) || this.state.buildingAmenities.map((amenities, key) => <button key={key} onClick={() => this.openAmenitiesInnewTab(amenities._id, key)}>
                        {amenities.label}
                    </button>)}
                </div>
            </div>
        </>;
    }
}

import axios from "axios";
import ENV from "../utils/env";

const listingV2Client = axios.create({
  baseURL: ENV.app_server_url_v2,
  headers: {
    'peek-api-key': ENV.peek_api_key
  }
});

export default class ListingService {
  getAllListings(params) {
    const path = `${ENV.app_server_url}/listings/get_nearby_listings?${params}`;
    return axios.get(path);
  }
  getListingAddress(params) {
    const path = `${ENV.app_server_url}/listings/get_listing_by_address`;
    return axios.post(path, params);
  }
  generateListingToken(listing_id = "") {
    const path = `${ENV.app_server_url}/listings/get_listing_webviewer_token?listing_id=${listing_id}&origin=website`;
    return axios.get(path);
  }
  getListingsByLayout(params) {
    const path = `${ENV.app_server_url}/listings/get_listings_by_layout`;
    return axios.post(path, params);
  }

  geListingLinkData(params) {
    const path = `${ENV.app_server_url}/listings/get_listing_link_data`;
    return axios.post(path, params);
  }

  updateCtaLog(params) {
    const path = `${ENV.app_server_url}/analytics/update_cta_click_log`;
    return axios.post(path, params);
  }

  // V2 APIs
  getListings(queryString = "") {
    const path = `/listings/gallery?${queryString}`;
    return listingV2Client.get(path);
  }

  getListingsByCategory(queryString = "") {
    const path = `/listings?${queryString}`;
    return listingV2Client.get(path);
  }

  getAllAmenities(queryString = "") {
    const path = `/amenities?${queryString}`;
    return listingV2Client.get(path);
  }

  getAgentDetails(agent_id) {
    const path = `/users/${agent_id}`;
    return listingV2Client.get(path);
  }

  getBuildingDetails(building_id) {
    const path = `/building/${building_id}`;
    return listingV2Client.get(path);
  }

  getAmenityToken(amenity_id) {
    const path = `/amenities/token/${amenity_id}`;
    return listingV2Client.get(path);
  }

  getListingToken(config) {
    return listingV2Client.get("/listings/token", config);
  }
}

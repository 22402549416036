import React, { useEffect, useState, useRef } from "react";
import ENV from "../../utils/env";
import ListingService from "../../services/listing.service";
import get from "lodash/get";
import Platform from "react-platform-js";
import Viewer from "./viewer";
import "./property.scss";
import ListingTile from "../grid/ListingInfoTile/ListingTile";
import { getQueryVariable } from "../../utils/helper";
import { useLocation } from "react-router";
import { ChevronDown, ChevronUp } from "@piiqtechnologies/piiq-icon-library";
import AmenitySlider from "./amenitySlider";
import Slider from "../Slider";
import PropTypes from "prop-types";
const Property = (props) => {
  const isAmenitySelection = useRef();
  const listingService = new ListingService();
  const [allListings, updateAllListings] = useState([]);
  const [availableFilters, updateFilters] = useState([]);
  const [forceUpdate, updateForceUpdate] = useState(get(props, "loadAmenities", true));
  const [filterToggle, toggleFilter] = useState(false);
  const [currentToken, updateToken] = useState("");
  const [selectedFilters, updateSelectedFilters] = useState([]);
  let [filterDataKeys, updateFilterDatakeys] = useState([]);
  const [allAmenities, updateAllAmenities] = useState([]);
  const [selectedListingId, updatedSelectedListingId] = useState("");
  const [selectedAmenity, updatedSelectedAmenity] = useState("");
  const [seeMoreAmenities, updatedSeeMoreAmenities] = useState(false);
  const [isLoading, updateIsLoading] = useState(true);
  const [brandingColor, updateBrandingColor] = useState("#04D2C6");
  const location = useLocation();
  let building_id = getQueryVariable("building_id", location.search.replace("?", ""));
  let agent_id = getQueryVariable("agent_id", location.search.replace("?", ""));

  useEffect(() => {
    if (building_id) {
      getBrandingDetails("getBuildingDetails", "secondary_color", building_id);
    } else if (agent_id) {
      getBrandingDetails("getAgentDetails", "accent_color", agent_id);
    }
    getAllParams().then((res) => {
      const isListingAvailable = Object.keys(res).length;
      if (get(props, "loadAmenities", true)) {
        getAllAmenities(isListingAvailable);
      }
    }).catch(err => {
      console.log(err);
      if (get(props, "loadAmenities", true)) {
        getAllAmenities();
      }
    });
  }, [location.search]);


  const getBrandingDetails = (func, key, value) => {
    listingService[func](value)
      .then((response) => {
        if (get(response, "data.status", "") !== "SUCCESS") {
          return;
        }
        const buildingInfo = get(response, "data.data.result", {});
        document.documentElement.style.setProperty("--blue", get(buildingInfo, key, "#04D2C6"));
        updateBrandingColor(get(buildingInfo, key, "#04D2C6"));
      })
      .catch((error) => {
        window.console.log(error);
      });
  }

  const getAllAmenities = (isLengthAvailable = false) => {
    let queryString = building_id ? `building_id=${building_id}&limit=0&start_index=0` : agent_id ? `agent_id=${agent_id}&limit=0&start_index=0` : "";
    listingService.getAllAmenities(queryString).then((response) => {
      updateIsLoading(false);
      if (get(response, "data.status", "") !== "SUCCESS") {
        return;
      }
      const data = get(response, "data.data.result", {});
      updateAllAmenities(data);
      if (!isLengthAvailable && data.length && window.innerWidth > 799) {
        changeAmenity(0, data[0]);
      }
      if (!data.length) {
        updateForceUpdate(false);
      }
    })
      .catch((error) => {
        updateIsLoading(false);
        window.console.log(error);
      });
  }

  const changeAmenity = (index, firstData = null) => {
    updatedSelectedListingId("");
    const selectedAmenityLocally = allAmenities[index] || firstData;
    if (!selectedAmenityLocally) {
      return;
    }
    if (get(selectedAmenityLocally, "label", "") === selectedAmenity) {
      return;
    }
    listingService
      .getAmenityToken(get(selectedAmenityLocally, "_id", ""))
      .then((response) => {
        if (get(response, "data.status", "") !== "SUCCESS") {
          return;
        }
        const data = get(response, "data.data.result", {});
        if (window.innerWidth < 800) {
          openInANewWindow(data.token, true);
        } else {
          updatedSelectedAmenity(get(selectedAmenityLocally, "label", ""));
          updateToken(data.token);
        }
      })
      .catch((error) => {
        window.console.log(error);
      });
  }

  const loadMoreListings = (queryString = "", key = "") => {
    listingService
      .getListingsByCategory(queryString)
      .then((response) => {
        updateIsLoading(false);
        if (get(response, "data.status", "") !== "SUCCESS") {
          return;
        }
        const data = get(response, "data.data.result", {});
        updateFilters(prevState => ({
          ...prevState,
          [`bedroom_${key}`]: [...availableFilters[`bedroom_${key}`], ...data],
        }));
      })
      .catch((error) => {
        window.console.log(error);
        updateIsLoading(false);
      });
  }

  const getAllParams = (bedroomString = "", isLimit = props.defaultCount, isLoadMoreListing = false) => {
    let queryString = building_id ? `building_id=${building_id}` : agent_id ? `agent_id=${agent_id}` : "";
    if (bedroomString) {
      bedroomString = bedroomString.replaceAll(",", "-");
      queryString += `&bedrooms=${bedroomString}`;
    }
    if (isLoadMoreListing) {
      queryString += `&start_index=${get(availableFilters, `bedroom_${bedroomString}`, []).length + 1}`;
      queryString += `&limit=${props.defaultLoadmore}`;
      loadMoreListings(queryString, bedroomString);
      return;
    }
    queryString += `&listing_limit=${isLimit}`;
    if (get(props, "isGrid", false)) {
      const show_csoon = getQueryVariable("show_csoon", location.search.replace("?", ""));
      const show_archived = getQueryVariable("show_archived", location.search.replace("?", ""));
      const show_hidden = getQueryVariable("show_hidden", location.search.replace("?", ""));
      queryString += `&show_coming_soon=${(show_csoon && show_csoon === "true") || false}`;
      queryString += `&show_archived=${(show_archived && show_archived === "true") || false}`;
      queryString += `&show_private=${(show_hidden && show_hidden === "true") || false}`;
    }
    return getListingsData(queryString);
  }

  const processLoadMoreListings = (key = "") => {
    if (!key) {
      return;
    }
    const bedroomString = key.replace("bedroom_", "");
    updateIsLoading(true);
    getAllParams(bedroomString, props.defaultLoadmore, true);
  }

  const processFilter = (listing_key) => {
    const newKey = listing_key.replace("bedroom_", "");
    const index = selectedFilters.indexOf(newKey);
    if (index === -1) {
      selectedFilters.push(newKey);
      updateSelectedFilters(selectedFilters);
    } else {
      selectedFilters.splice(index, 1);
      updateSelectedFilters(selectedFilters);
    }
    const limitCount = selectedFilters && selectedFilters.length ? props.defaultLoadmore : props.defaultCount;
    getAllParams(selectedFilters.toString(), limitCount);
  }

  const openInANewWindow = (token, is_amenity = false) => {
    if (Platform.Browser === "Safari" || Platform.Browser === "Mobile Safari") {
      if (window.self !== window.top) {
        window.top.location.href = `${ENV.app_url}/viewer?token=${token}&is_amenity=${is_amenity}&fromShareable=true`;
      } else {
        window.location.href = `${ENV.app_url}/viewer?token=${token}&is_amenity=${is_amenity}&fromShareable=true`;
      }
    } else {
      window.open(`${ENV.app_url}/viewer?token=${token}&is_amenity=${is_amenity}`, "_blank");
    }
  }

  const getTokenAndRedirect = (listing_id) => {
    if (selectedListingId === listing_id) {
      return;
    }
    const listingTokenParamsConfig = {
      listing_id,
      origin: "website",
      is_embedded: false
    }
    listingService.getListingToken({ params: listingTokenParamsConfig }).then(response => {
      if (get(response.data, "status", "ERROR") === "SUCCESS") {
        const { token } = get(response, "data.data.result", {});
        if (window.innerWidth < 800 || get(props, "isGrid", false)) {
          openInANewWindow(token);
        } else {
          updatedSelectedListingId(listing_id);
          resetAmenities();
          updateToken(token);
        }
      }
    });
  };
  const resetAmenities = () => {
    updatedSelectedAmenity("");
    if (isAmenitySelection && isAmenitySelection.current) {
      isAmenitySelection.current.resetAmenitySelection();
    }
  }

  const getListingsData = (queryString = "") => {
    return new Promise((resolve, reject) => {
      updateIsLoading(true);
      if (!queryString) {
        return reject({});
      }
      listingService
        .getListings(queryString)
        .then((response) => {
          if (!get(props, "loadAmenities", true)) {
            updateIsLoading(false);
          }
          if (get(response, "data.status", "") !== "SUCCESS") {
            return reject({});
          }
          const data = get(response, "data.data.result", {});
          let filteredData = {};
          if (data) {
            Object.keys(data).map((key) => {
              if (key.indexOf("_size") === -1 && data[`${key}_size`] > 0) {
                filteredData[key] = data[key];
              }
              return null;
            });
            updateFilters(filteredData);
            updateAllListings(data);
            let firstElement = Object.keys(filteredData);
            if (firstElement && firstElement.length && window.innerWidth > 799 && !get(props, "isGrid", false)) {
              firstElement = firstElement[0];
              let listing = filteredData[firstElement];
              listing = listing && listing[0] && listing[0]._id;
              getTokenAndRedirect(listing);
              updatedSelectedListingId(listing);
              resetAmenities();
            }
            if (filterDataKeys && !filterDataKeys.length) {
              filterDataKeys = Object.keys(filteredData);
              updateFilterDatakeys(filterDataKeys);
            }
          }
          resolve(filteredData);
        })
        .catch((error) => {
          updateIsLoading(false);
          window.console.log(error);
          return reject({});
        });
    });
  }

  function getKeyName(key) {
    key = key.toLowerCase();
    var apartMentType = {
      bedroom_0: "Studio",
      bedroom_1: "1 bedroom",
      bedroom_2: "2 bedrooms",
      bedroom_3: "3+ bedrooms"
    }
    return apartMentType[key] || "";
  }

  return (<div className={["listing_view",
    (get(props, "isGrid", false) && "grid_wrapper") || ""
  ].join(" ")}>
    {(get(props, "loadAmenities", true) && allAmenities && allAmenities.length > 0 && <div className="mobile_view__amenities">
      <div className="mobile_view__header">
        <h4 className="title">Community amenities</h4>
        {(allAmenities && allAmenities.length > 3 && <div className={["filter_text",
          seeMoreAmenities && "is_active"
        ].join(" ")} onClick={() => { updatedSeeMoreAmenities(!seeMoreAmenities) }}>{seeMoreAmenities ? `See less` : `See more`}<span>{(seeMoreAmenities && <ChevronUp />) || (!seeMoreAmenities && <ChevronDown />)}</span></div>) || null}
      </div>
      <AmenitySlider data={allAmenities} changeAmenity={(index) => { changeAmenity(index) }} seeMoreAmenities={seeMoreAmenities} amenityKey={"label"} />
    </div>) || null}
    <div className={["listing__left_wrapper",
      (get(props, "isGrid", false) && "is_grid") || ""
    ].join(" ")}>
      <div className="listing__header">
        <div className="titles">
          {(!(Object.keys(availableFilters).length === 0 && allAmenities.length === 0) && <h4 className="availabilities_title">Availabilities</h4>) || null}
          {(Object.keys(availableFilters).length > 0 && <div className={
            ["filter__toggle",
              filterToggle && "is_active"
            ].join(" ")} onClick={() => { toggleFilter(!filterToggle) }}>Filter
            <span className="filter_icon">{(filterToggle && <ChevronUp />) || (!filterToggle && <ChevronDown />)}</span>
          </div>) || null}
        </div>
        {(filterToggle && <div className="filters">
          {filterDataKeys.map((listing_key) => {
            return (<div className={
              ["filter",
                selectedFilters.indexOf(listing_key.replace("bedroom_", "")) > -1 && "is_active"
              ].join(" ")} key={listing_key} onClick={() => { processFilter(listing_key) }}>{getKeyName(listing_key)}</div>)
          })
          }
        </div>) || null}
      </div>
      {
        Object.keys(availableFilters).map((listing_key) => {
          return <div key={listing_key} className="apartment_type__wrapper">
            <div className="apartment_type">{getKeyName(listing_key)}</div>
            <div className="listing__thumbnails">
              {availableFilters[listing_key].map((listing, index) => {
                return <ListingTile onClick={() => getTokenAndRedirect(listing._id)} selectedListingId={selectedListingId} key={index} data={listing} />
              })}
            </div>
            {(get(allListings, `${listing_key}_size`, 0) > get(availableFilters, listing_key, []).length && <div className="load_more" style={{ pointerEvents: isLoading ? "none" : "all" }} onClick={() => { processLoadMoreListings(listing_key) }}>See more</div>) || null}
          </div>
        })
      }
      {!Object.keys(availableFilters).length && !isLoading && allAmenities.length > 0 && <p className="no_tour_message">No virtual tours available.</p>}
    </div>
    {!Object.keys(availableFilters).length && !isLoading && !allAmenities.length && <p className="no_tours">No virtual tours available.</p>}
    {(currentToken && <div className="listing__right_wrapper">
      {(get(props, "loadAmenities", true) && allAmenities && allAmenities.length > 0 && <div className="amenity__slider">
        <h4 className="amenity__title">Community amenities</h4>
        <div className="main_slider">
          <Slider data={allAmenities} amenityKey={"label"} selectorColor={brandingColor} onChange={(index) => changeAmenity(index)} firstAmenity={selectedAmenity} ref={isAmenitySelection} />
        </div>
      </div>) || null}
      {<Viewer token={currentToken} noAmenities={get(props, "loadAmenities", true) && forceUpdate} isAmenity={!!selectedAmenity} />
      }
    </div>) || null}
  </div>);
}

Property.defaultProps = {
  loadAmenities: true,
  isGrid: false,
  defaultCount: 3,
  defaultLoadmore: 10
}

Property.propTypes = {
  loadAmenities: PropTypes.bool
}

export default Property;
import React from "react";
import { PureComponent } from "react";
import "../../App.scss";

class MobileIframe extends PureComponent {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render () {
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    return <iframe allowvr="yes" src={this.props.src} frameBorder='0' width="100%" height="272px" ></iframe>;
  }
}

export default React.memo(MobileIframe);



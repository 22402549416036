const ENVIRONMENT = {
    // Dev URLs
    // "app_server_url": 'https://api-dev.peek.us/rest/v1',
    // "app_server_url_v2": "https://listings-api-dev.peek.us/rest/v2",
    // 'image_base_path': 'https://piiq-uploads-dev.s3.amazonaws.com/',
    // 'amenities_image_base_path': 'https://piiq-dev-upload-amenities.s3.amazonaws.com/',
    // 'agent_lens_base_path': 'https://lens-dev.peek.us/',
    // "app_url": 'https://listings-dev.peek.us',
    // "branding_image_base_path": 'https://piiq-dev-upload-branding.s3.amazonaws.com/',
    // "s3_pool_id": 'us-east-1:3313ffcc-3d39-499a-aee3-031d52b5d5cb',
    // "s3_bucket": 'piiq-dev-upload-branding',
    // "user_profile_image_base_path": 'https://piiq-users-profile-dev.s3.amazonaws.com/',
    // "agents_site_base_path": "https://manage-dev.peek.us/mysite/",
    // "peek_api_key": "029b5bdf-8ca8-4d7a-8211-fffe05d098b5",


    // Preview URLs
    "app_server_url": 'https://preview-api.piiqvr.com/rest/v1',
    "app_server_url_v2": "https://listings-api.peek.us/rest/v2",
    'image_base_path': 'https://piiq-uploads-preview.s3.amazonaws.com/',
    'amenities_image_base_path': 'https://piiq-preview-upload-amenities.s3.amazonaws.com/',
    "app_url": 'https://listings.peek.us',
    "branding_image_base_path": 'https://piiq-preview-upload-branding.s3.amazonaws.com/',
    "s3_pool_id": 'us-east-1:3313ffcc-3d39-499a-aee3-031d52b5d5cb',
    "s3_bucket": 'piiq-preview-upload-branding',
    'agent_lens_base_path': 'https://lens.peek.us/',
    "user_profile_image_base_path": ' https://piiq-users-profile-preview.s3.amazonaws.com/',
    "agents_site_base_path": "https://manage.peek.us/mysite/",
    "peek_api_key": "f234cc42-1742-40c8-99db-4ecd51046541",


    website_link: "https://www.peek.us"
}

export {ENVIRONMENT as default};

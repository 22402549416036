import React from "react";

const loader = () => {
  return (
    <div className="loader loader-1">
      <div className="icon-wrapper">
        <i className="fa fa-home" aria-hidden="true"></i>
      </div>
    </div>
  );
};
export default loader;

/* eslint-disable no-mixed-operators */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {  get } from "lodash";
import { ChevronLeft, ChevronRight } from "@piiqtechnologies/piiq-icon-library";


export default class Slider extends Component {
    constructor (props) {
        super(props);
        this.state = {
            leftScroll: 0,
            showRightScroll: false,
            amenity: get(this.props, "firstAmenity", "")
        };
        this.onSelect = this.onSelect.bind(this);
        this.resetAmenitySelection = this.resetAmenitySelection.bind(this);
    }

    convertToLowerCase(string = "") {
        string = (string && string.toLowerCase()) || "";
        string = string.charAt(0).toUpperCase() + string.slice(1);
        return string; 
    }

    componentDidMount() { 
        if (get(this.props, "data", false).length > 0) {
        const scrollWidth = parseInt(document.getElementsByClassName("amenities_div")[0].scrollWidth);
        const clientWidth = parseInt(document.getElementsByClassName("amenities_div")[0].clientWidth);
        const scrollLeft = parseInt(document.getElementsByClassName("amenities_div")[0].scrollLeft);
        if ((scrollWidth !== clientWidth) && scrollLeft === 0) {
            this.setState({ showRightScroll: true });
        } 
    }
    }

    resetAmenitySelection(){
        this.setState({ amenity: "" });
    }

    onSelect (key) {
        this.props.data.forEach((amenity, index) => {
            if (parseInt(key) === index) {
                this.props.onChange(index);
                this.setState({ amenity: amenity[this.props.amenityKey]});
            }
        })
      }

      scrollToLeft () {
        const parent = document.getElementsByClassName("amenities_div");
        parent[0].scrollLeft = parent[0].scrollLeft + 200;
        const scrollToLeft = parent[0].scrollLeft;
        this.setState({leftScroll: scrollToLeft});
        const clientWidth = Math.ceil(parent[0].clientWidth);
        const scrollWidth = Math.ceil(parent[0].scrollWidth);
        if (parseInt(scrollToLeft) + clientWidth === scrollWidth) {
            this.setState({showRightScroll: false});
        }
      };

      scrollToRight () {
        const parent = document.getElementsByClassName("amenities_div");
        parent[0].scrollLeft = parent[0].scrollLeft - 200;
        const scrollToLeft = parent[0].scrollLeft;
        this.setState({leftScroll: scrollToLeft});
        const clientWidth = Math.ceil(parent[0].clientWidth);
        const scrollWidth = Math.ceil(parent[0].scrollWidth);
        if (parseInt(scrollToLeft) + clientWidth !== scrollWidth) {
            this.setState({showRightScroll: true});
        }
      };

    render () {
        return (
            <>
                { get(this.props, "data", false).length > 0 && get(this.props, "amenityKey", false) && 
                    <div className="slider">
                        {this.state.leftScroll !== 0 && <span style={{color: this.props.selectorColor || "#04D2C6"}} onClick={() => this.scrollToRight()} className="left_amenity">
                                <ChevronLeft />
                         </span> || null}
                        <div className="amenities_div">
                            {this.props.data.map((amenity, index) => {
                                return <span key={index} onClick={() => { this.onSelect(index) }}
                                className={"amenity_name"}>
                                    <div className="bar_indicator" style={{backgroundColor: this.state.amenity === amenity[this.props.amenityKey] ? this.props.selectorColor || "#04D2C6" : ""}}></div>
                                    <span style={{ padding: 10 }}>{amenity[this.props.amenityKey]}</span></span>
                            })}
                        </div>
                        {this.state.showRightScroll && <span style={{color: this.props.selectorColor || "#04D2C6"}} onClick={() => this.scrollToLeft()} className="right_amenity">
                            <ChevronRight />
                        </span> || null}
                    </div> || null
                }    
            </> 
        );
    }
}

Slider.propTypes = {
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    amenityKey: PropTypes.string,
    selectorColor: PropTypes.string,
    firstAmenity: PropTypes.string
};